import { Trans, t } from "@lingui/macro";
import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import styled from "@emotion/styled";
import React, { useState } from "react";
import HeroLanding from "../../../components/HeroLanding";
import SecondaryButton from "../../../components/SecondaryButton";
import Seo from "../../../components/seo";
import Header from "../../../components/Header";
import { theme } from "../../../themeV2";
import ProductModal from "../../../components/ProductModal";
import DefaultSection from "../../../components/landings/DefaultSection";
import ArrowButton from "../../../components/ArrowButton";
import CardColor from "../../../components/CardColor";
import heroImg from "../../../images/solutions/digital-wealth/custom-reporting/hero.png";
import sec1Img from "../../../images/solutions/digital-wealth/custom-reporting/sec-1.png";
import sec2VideoPoster from "../../../images/solutions/digital-wealth/custom-reporting/sec-2-poster.png";
import sec3Img from "../../../images/solutions/digital-wealth/custom-reporting/sec-3.png";
import sec4Img from "../../../images/solutions/digital-wealth/custom-reporting/sec-4.png";
import sec5Img from "../../../images/solutions/digital-wealth/custom-reporting/sec-5.png";
import sec6VideoPoster from "../../../images/solutions/digital-wealth/custom-reporting/sec-6-poster.png";
import purpleIcon from "../../../images/solutions/digital-wealth/custom-reporting/icon-purple.svg";
import greenIcon from "../../../images/solutions/digital-wealth/custom-reporting/icon-green.svg";
import blueIcon from "../../../images/solutions/digital-wealth/custom-reporting/icon-blue.svg";
import yellowIcon from "../../../images/solutions/digital-wealth/custom-reporting/icon-yellow.svg";
const sec2Video = "https://app.allfunds.com/docs/cms/sec_2_9b21b79085.mp4";
const sec6Video = "https://app.allfunds.com/docs/cms/sec_6_2c02941eb9.mp4";

const metadata = {
  title: "Custom Reporting",
  description:
    "Customise your reports to your brand and corporate image and comply with the regulation. Exceed your advisors' and clients' expectations with a full suite of stunning reports.",
};

const CARDS_ITEMS = [
  {
    icon: yellowIcon,
    color: theme.palette.colors.yellow.main,
    title: <Trans>Advanced performance and risk metrics</Trans>,
    description: (
      <Trans>
        Advanced metrics offer clear insights into investment performance and
        risk, empowering informed decisions.
      </Trans>
    ),
  },
  {
    icon: greenIcon,
    color: theme.palette.colors.green.main,
    title: <Trans>Detailed portfolio allocation breakdowns</Trans>,
    description: (
      <Trans>
        Get detailed portfolio breakdowns for clear insights and confident,
        informed investment decisions.
      </Trans>
    ),
  },
  {
    icon: purpleIcon,
    color: theme.palette.colors.purple.main,
    title: <Trans>Customizable branding and reporting</Trans>,
    description: (
      <Trans>
        Customizable branding and reporting enhance professional image and
        tailored insights for clients and investors.
      </Trans>
    ),
  },
  {
    icon: blueIcon,
    color: theme.palette.colors.blue.chart,
    title: <Trans>MiFID II and PRIIPs compliant</Trans>,
    description: (
      <Trans>
        Fully MiFID II and PRIIPs compliant, ensuring transparent, secure, and
        client-focused investment solutions.
      </Trans>
    ),
  },
];

function CustomReporting() {
  const [showModal, setShowModal] = useState(false);
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const renderRequestButton = () => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => setShowModal(true)}
        text={<Trans>Request info</Trans>}
      />
    );
  };

  const renderArrowButton = (link) => {
    return (
      <CustomArrowButton
        isDark
        variant="text"
        color="secondary"
        title="Know more"
        contentColor={theme.palette.secondary.main}
        extraStyles={{ px: "0 !important" }}
        onClick={() => window.open(link, "_blank")}
      />
    );
  };

  const renderCard = ({ color, icon, title, description, key }) => {
    return (
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          transform: {
            sm: key % 2 === 0 ? "translateY(0)" : "translateY(24px)",
            md: "none",
          },
          marginTop: { md: key === 1 ? 2 : "0" },
          marginBottom: { md: key === 3 ? 2 : "0" },
          paddingTop: key === 0 && "0!important",
        }}
      >
        <CardColor
          mt={0}
          color={color}
          icon={icon}
          title={title}
          description={description}
        />
      </Grid>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Seo title={metadata.title} description={metadata.description} />
      <Header />
      <HeroLanding
        title={
          <Trans>
            Elevate Your Reporting with Customized Fund and Portfolio Insights
          </Trans>
        }
        description={
          <Trans>
            Customise your reports to your brand and corporate image and comply
            with the regulation. Exceed your advisors' and clients' expectations
            with a full suite of stunning reports.
          </Trans>
        }
        handleOnclickInfo={() => setShowModal(true)}
        multimedia={heroImg}
        widthImage={{ xs: "80%", md: "100%" }}
        kind="Custom Reporting"
        kindColor={theme.palette.colors.green.main}
        buttonJustify={{ xs: "center", sm: "flex-start" }}
        grid={{
          columnLeft: { md: 6.5 },
          columnRight: { md: 5.5 },
        }}
      />
      <Stack component="span" backgroundColor={theme.palette.colors.white.dark} mt={{md: "-36px"}}>
        <DefaultSection
          direction="row-reverse"
          pretitle={t`Fund Factsheets`}
          containerPy={{ xs: 8, lg: 5 }}
          pretitleColor={theme.palette.colors.green.main}
          title={t`Generate, publish and distribute stunning fund factsheets`}
          description={
            <>
              <Typography>
                <Trans>
                  Impress your clients and advisors with professionally designed
                  fund factsheets. Access data on over 180,000 funds, updated
                  daily, and effortlessly generate reports that are both
                  informative and visually appealing.
                </Trans>
              </Typography>
              <ul>
                <li><Trans>Expertly designed and client-friendly</Trans></li>
                <li><Trans>Data updated daily (performance, risk, holdings, ESG)</Trans></li>
                <li><Trans>MiFID II and PRIPPs compliant</Trans></li>
                <li><Trans>Standard and custom templates</Trans></li>
                <li><Trans>Available via API Rest</Trans></li>
              </ul>
            </>
          }
          multimedia={sec1Img}
          buttons={renderRequestButton()}
          buttonsJustify={{ xs: "flex-start" }}
        />
      </Stack>
      <DefaultSection
        direction="row-reverse"
        pretitle={t`Fund Comparison`}
        pretitleColor={theme.palette.colors.green.main}
        title={t`Side-by-side comparison of more than 180,000 mutual funds`}
        description={
          <>
            <Typography>
              <Trans>
                Empower your clients to make informed decisions. Compare up to
                four funds simultaneously, analyzing key metrics like
                performance, risk, and asset allocation. Generate comprehensive
                comparison reports that are easy to understand and share.
              </Trans>
            </Typography>
            <ul>
              <li>Compare up to 4 funds side-by-side</li>
              <li>Clear and concise performance visualizations</li>
              <li>Detailed risk and asset allocation breakdowns</li>
              <li>Customizable reports</li>
            </ul>
          </>
        }
        isVideo={true}
        multimedia={sec2Video}
        videoPoster={sec2VideoPoster}
        buttons={renderRequestButton()}
        buttonsJustify={{ xs: "flex-start" }}
      />
      <DefaultSection
        pretitle={t`Client & Model Portfolios`}
        textAlign={{ xs: "center", md: "left" }}
        pretitleAlign={{ xs: "center", md: "left" }}
        pretitleColor={theme.palette.colors.green.main}
        title={t`Scale Your Portfolio Reporting with Customized Insights`}
        description={
          <>
            <Typography>
              <Trans>
                Seamlessly generate sophisticated portfolio reports that
                showcase performance, risk, and allocation with compelling
                visuals. Customize reports with your branding and ensure
                compliance with MiFID II and PRIIPs regulations. Available via
                API
              </Trans>
            </Typography>
            <Stack display={{ md: "none" }} alignItems="center">
              {renderRequestButton()}
            </Stack>
            <Grid
              container
              spacing={2}
              ml="-24px!important"
              pb={{ sm: 2, md: 0 }}
            >
              {CARDS_ITEMS.map((item, key) => renderCard({ ...item, key }))}
            </Grid>
          </>
        }
        multimedia={sec3Img}
        buttons={
          !isTablet && (renderRequestButton())
        }
        buttonsJustify={{ xs: "flex-start" }}
      />
      <DefaultSection
        pt={{ xs: "48px", sm: "inherit" }}
        direction="row-reverse"
        pretitle={t`Financial Plannig`}
        pretitleColor={theme.palette.colors.green.main}
        title={t`Visualize the Future with Powerful Financial Simulations`}
        description={
          <Typography>
            <Trans>
              Create compelling financial simulation reports that demonstrate
              how your clients' portfolios can help them achieve their financial
              goals.
            </Trans>
          </Typography>
        }
        multimedia={sec4Img}
        buttons={renderRequestButton()}
        buttonsJustify={{ xs: "flex-start" }}
      />
      <Stack
        component="span"
        borderRadius="48px 48px 0 0"
        backgroundColor={theme.palette.colors.white.dark}
      >
        <Typography
          mt={{ xs: 4, sm: 3, md: 7 }}
          textAlign="center"
          fontWeight={700}
          fontSize={{ xs: "36px", sm: "48px" }}
        >
          <Trans>Related Products</Trans>
        </Typography>
        <DefaultSection
          pretitle={t`Regulatory Reporting`}
          pretitleColor={theme.palette.colors.green.main}
          title={t`Enhance compliance and streamline publication processes`}
          description={
            <Typography>
              <Trans>
                Allfunds’ Regulatory Reporting platform ensures compliance with
                regulations and streamlines efficient document distribution,
                including KID PRIIPs, EMT, EPT, EET, Factsheets, and ESG
                reports. (Principal Adverse Impact and EU Taxonomy analysis provided by MainStret Partner).
              </Trans>
            </Typography>
          }
          multimedia={sec5Img}
          buttons={renderArrowButton(
            "/solutions/regulatory/regulatory-reporting",
          )}
          buttonsJustify={{ xs: "flex-start" }}
        />
        <CustomDefaultSection
          direction="row-reverse"
          pretitle={t`ESG Reporting`}
          pretitleColor={theme.palette.colors.green.main}
          title={t`Introducing our ESG section with EET and MainStreet Partners ratings, Impact Metrics, and PAI data`}
          description={
            <Typography>
              <Trans>
                You can now present customers extra-financial results while
                ensuring regulatory compliance, transparency, and market
                best-practice.
              </Trans>
            </Typography>
          }
          isVideo={true}
          multimedia={sec6Video}
          videoPoster={sec6VideoPoster}
          buttons={renderArrowButton("/solutions/esg/esgeverything")}
          buttonsJustify={{ xs: "flex-start" }}
        />
      </Stack>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject="Custom Reporting"
          product="custom-reporting"
          hasTemplate={false}
        />
      )}
    </ThemeProvider>
  );
}

const CustomDefaultSection = styled(DefaultSection)`
    video {mix-blend-mode: multiply}
`;
const CustomArrowButton = styled(ArrowButton)`
    p {font-weight: 700!important}
`;

export default CustomReporting;
